<template>
  <div class="EmpresasMain mb24">

    <vue-page-transition name="fade">
      <router-view
        @msg="$emit('msg',$event)"
        @httpError="$emit('httpError',$event)"
      />
    </vue-page-transition>
    <br><br>
  </div>
</template>
<script>
export default {
  name: "EmpresasMain",
  data() {
    return {
      showClasses: "",
      snackbar: {
        show: false,
        text: "",
      },
      showAll: false,
      isLogedIn: false,
    };
  },
  methods: {
    _handleMsg(event) {
      // this.$emit('msg',$event)
    },
    _handleHttpError(event) {
      // this.$emit('httpError',$event)
    },
    _notify(a) {
      this.snackbar.text = a;
      this.snackbar.show = true;
    },
    _resFail(a) {
      if (a.status === 401) {
        this.$router.push("/login");
      } else if (a.status === 400) {
        this._notify(a.body.error);
      } else if (a.body.error) {
        this._notify(a.body.error);
      } else {
        this._notify(this.$noInternet);
      }
    },
    _hasSession() {
      let esRegistro = this.$route.name != "EmpresasRegistro";
      if (
        (!this.$session.exists() || this.$session.get("tipo") != "donador") &&
        esRegistro
      ) {
        this._notify("Es necesario inicies sesión");
        this.$router.push("/empresas/login");
        this.$session.destroy();
      } else {
        this.isLogedIn = this.$session.exists() ? true : false;
        this.$headers.headers.Token =
          this.$session.exists() && this.$session.get("token")
            ? this.$session.get("token")
            : null;
      }
    },
  },
  created() {
    this._hasSession();
  },
  watch: {
    $route(a, b) {
      this._hasSession();
    },
  },
};
</script>